@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
}

body > #root {
  margin: 0 auto;
  /*height: 100%;*/
}

body {
  margin: 0;
}

body,
html {
  height: 100%;
  width: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  font-family: pretendard, sans-serif;
}

#root {
  /* overflow: auto; */
  background-color: #ffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
